import _log from "utilise/log";
import _includes from "utilise/includes";
import _client from "utilise/client";
import _attr from "utilise/attr";
import _djbx from "djbx";
var exports = {};

exports = function (ripple) {
  return log("creating"), ripple.types["text/css"] = {
    header: "text/css",
    ext: "*.css",
    selector: res => `[css~="${res.name}"]`,
    extract: el => (attr("css")(el) || "").split(" "),
    check: res => includes(".css")(res.name),
    shortname: path => basename(path),
    load: !1,
    parse: res => (res.headers.hash = res.headers.hash || hash(res.body), res)
  }, ripple;
};

const log = _log("[ri/types/css]"),
      includes = _includes,
      client = _client,
      attr = _attr,
      hash = _djbx;

var basename, file;
export default exports;